<template>
  <div>
    <label class="label" :class="{'required': required}">{{label}}</label>
    <v-textarea
        outlined
        dense
        :rules="rules"
        rows="2"
        :value="value"
        @input="valueChanged"
        :disabled="disabled"
        :readonly="readonly"
    ></v-textarea>
  </div>
</template>

<script>
export default {
    props: {
        value: {
            default: "",
        },
        label: {
            required: true
        },
        required: {
            type: Boolean,
            default: false
        },
        rules: {
            
        },
        disabled: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },
    methods : {
        valueChanged(val) {
            //to prevent error in null value
            this.$emit("input", val);
        }
    }
}
</script>