import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import app from './app'
import vehicle from './vehicle'
import jobtracker from './jobtracker'
import vehicle_lookup from './lookups/vehicle_lookup'
import jobtracker_lookup from './lookups/jobtracker_lookup'
import conversion_lookup from './lookups/conversion_lookup'
import equipment_lookup from './lookups/equipment_lookup'
import contract_lookup from './lookups/contract_lookup'
import service_schedule_lookup from './lookups/service_schedule_lookup'
import financial_lookup from './lookups/financial_lookup'
import location_lookup from './lookups/location_lookup'
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    createPersistedState()
  ],
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    app,
    vehicle,
    jobtracker,
    vehicle_lookup,
    jobtracker_lookup,
    conversion_lookup,
    equipment_lookup,
    contract_lookup,
    service_schedule_lookup,
    financial_lookup,
    location_lookup
  }
})
