import { ROLE } from '../../../enums/roles';
import { STORE } from '../../../enums/store';
export default {
    authorized_locations(state, getters, rootState) {
        const user = rootState[STORE.AUTH].user;
        if(user) {
            if(user.role_id === ROLE.SUPER_ADMIN) {
                return state.locations;
            } else {
                return user.location ? state.locations.filter(x => user.location.indexOf(x.id) !== -1) : [];
            }
        }
        return [];
    }
}