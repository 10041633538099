import common from '../common';
import objectHelper from '../helpers/objectHelper';
import dateHelper from '../helpers/dateHelper';
import { HTTP_METHODS, HTTP_RESPONSE } from '../enums/http';
import { STORE } from '../enums/store';
export default class SupplierService {
    constructor(utils) {
        this.utils = utils;
    }
    async getSuppliers(per_page) {
        let response = await common.loadAllResults({
            url: `/api/getSupplier/${per_page}`,
            utils: this.utils
        });
        return response.map(x => ({
            id: x.id,
            supp_name: x.supp_name,
            supp_address: x.supp_address,
            supp_address1: x.supp_address1,
            supp_address2: x.supp_address2,
            supp_address3: x.supp_address3,
            supp_address4: x.supp_address4,
            pin_code: x.pin_code,
            supp_contact_number: x.supp_contact_number,
            supp_email: x.supp_email,
            lookup_location_id:x.lookup_location_id,
            is_active: x.is_active ? true : false,
            supplier_details: x.supplierDetails,
            document_id: x.document_id,
            supplier_accounts: x.supplierAccount.map(y => {
                return {
                    ...y,
                    agrement_start_date: dateHelper.formatDateReverse(y.agrement_start_date),
                    agrement_end_date: dateHelper.formatDateReverse(y.agrement_end_date)
                }
            }),
        })) || []
    }
    
    async getSupplierByStringMatch(text) {
        return this.utils.$store.state[STORE.APP].suppliers.filter(x => x.supp_name?.toLowerCase()?.indexOf(text.toLowerCase()) !== -1) || [];

    }
    async getSupplierDetails(supplier_id) {
        let response = await common.getRequest({
            url: `/api/supplierDetails/${supplier_id}`,
            utils: this.utils
        });
        if(response.status === HTTP_RESPONSE.SUCCESS) {
            return response.data.map(x => ({
                id: x.id,
                priority: x.priority,
                name: x.name,
                job_title: x.job_title,
                lookup_location_id: x.lookup_location_id,
                phone: x.phone,
                email: x.email,
                supplier_id: x.supplier_id,
                address: x.address,
                address1: x.address1,
                address2: x.address2,
                address3: x.address3,
                address4: x.address4,
                pin_code: x.pin_code,
            })); 
        } else {
            return [];
        }
    }
    getSupplierById(supplier_id) {
        return this.utils.$store.state[STORE.APP].suppliers.find(x => x.id === supplier_id) || null;
    }
    async saveSupplier(inputs, method = HTTP_METHODS.POST) {
        let payload = Object.assign({},{
            ...inputs,
        });
        delete payload.supplier_details;
        return await common.saveRequest({
            method,
            url: method === HTTP_METHODS.POST ? `/api/supplier` : `/api/updateSupplier/${inputs.id}`,
            payload,
            utils: this.utils
        });
    }
    async addSupplierDetails(supplier_details, supplier_id) {
        for (let i = 0; i < supplier_details.length; i++) {
            let payload = {
                ...supplier_details[i],
            };
            await common.saveRequest({
                method: HTTP_METHODS.POST,
                url: `/api/supplier/${supplier_id}/supplierDetails`,
                payload,
                utils: this.utils
            });
        }
    }
    async addSupplierAccounts(supplier_accounts, supplier_id) {
        for (let i = 0; i < supplier_accounts.length; i++) {
            let payload = {
                ...supplier_accounts[i],
                agrement_start_date: dateHelper.formatDateTime(supplier_accounts[i].agrement_start_date),
                agrement_end_date: dateHelper.formatDateTime(supplier_accounts[i].agrement_end_date),
            };
            await common.saveRequest({
                method: HTTP_METHODS.POST,
                url: `/api/supplier/${supplier_id}/supplierAccounts`,
                payload,
                utils: this.utils
            });
        }
    }
    async updateSupplierDetails(supplier_details) {
        for (let i = 0; i < supplier_details.length; i++) {
            let payload = {
                ...supplier_details[i],     
            };
            await common.saveRequest({
                method: HTTP_METHODS.PUT,
                url: `/api/updateSupplierDeatils/${payload.id}`,
                payload,
                utils: this.utils
            });
        }
    }
    async updateSupplierAccounts(supplier_accounts) {
        for (let i = 0; i < supplier_accounts.length; i++) {
            let payload = {
                ...supplier_accounts[i],
                agrement_start_date: dateHelper.formatDateTime(supplier_accounts[i].agrement_start_date),
                agrement_end_date: dateHelper.formatDateTime(supplier_accounts[i].agrement_end_date),
            };
            await common.saveRequest({
                method: HTTP_METHODS.PUT,
                url: `/api/updateSupplierAccounts/${payload.id}`,
                payload,
                utils: this.utils
            });
        }
    }
    async saveSupplierDetails(supplier, supplier_id, old_supplier_details= null) {
        let { added, updated } = objectHelper.difference(supplier.supplier_details, old_supplier_details);
        added.length && await this.addSupplierDetails(added, supplier_id);
        updated.length && await this.updateSupplierDetails(updated);
        return {
            status: HTTP_RESPONSE.SUCCESS
        };
    }
    async saveSupplierAccounts(supplier, supplier_id, old_supplier_details= null) {
        let { added, updated } = objectHelper.difference(supplier.supplier_accounts, old_supplier_details);
        added.length && await this.addSupplierAccounts(added, supplier_id);
        updated.length && await this.updateSupplierAccounts(updated);
        return {
            status: HTTP_RESPONSE.SUCCESS
        };
    }
    async deleteSupplier(supplier) {
        return await common.deleteRequest({
            url: `/api/removeSupplier/${supplier.id}`,
            utils: this.utils
        });
    }
    async deleteSupplierDetails(supplier_details) {
        return await common.deleteRequest({
            url: `/api/removeSupplierDetails/${supplier_details.id}`,
            utils: this.utils
        });
    }
    async deleteSupplierAccounts(supplier_details) {
        return await common.deleteRequest({
            url: `/api/removeSupplierAccounts/${supplier_details.id}`,
            utils: this.utils
        });
    }
}