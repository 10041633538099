<template>
  <div class="mb-3 legend-block">
    <div class="legend-title mb-4 pt-2">
      <img v-if="imageurl" class="legend-img" :src="imageurl" alt="" />
      <span class="subtitle-1 text--secondary"> {{ title }} </span>
      <slot name="header_append"></slot>
    </div>
    <v-container fluid>
      <slot></slot>
    </v-container>
  </div>
</template>

<script>
export default {
  props: {
    title: {
    },
    imageurl: {
    },
  },
};
</script>

<style lang="scss">
.legend {
  &-block:nth-child(odd) {
    background-color: $app-base-light-color-secondary;
  }
  &-title {
    padding-left: 10px;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  &-img {
    height: 30px;
    width: 30px;
  }
}
</style>