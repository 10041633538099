<template>
  <div>
    <label class="label" :class="{'required': required}">{{label}}</label>
    <v-select
        outlined
        dense
        :rules="rules"
        :value="value"
        :items="items"
        :disabled="disabled"
        @change="valueChanged"
    ></v-select>
  </div>
</template>

<script>
export default {
    props: {
        value: {
            default: "",
        },
        label: {
            required: true
        },
        required: {
            type: Boolean,
            default: false
        },
        rules: {
            
        },
        items: {
            default: []
        },
        disabled: {
            type: Boolean,
            default: false
        },
    },
    methods : {
        valueChanged(val) {
          this.$emit("input", val);
          this.$emit("change");
        }
    }
}
</script>

<style>

</style>