export default {
    difference(newOjb, oldObj, excludeKeys = []) {
        let added = [], updated = [], deleted = [];
        added = newOjb?.filter(x => Number.isInteger(x.id)) || [];    
        if(oldObj) {
            for(let i =0; i < oldObj.length; i++) {
                let newobj = newOjb.find(x => x.id === oldObj[i].id)
                if(newobj) {
                    let keys = Object.keys(oldObj[i]).filter(x => !excludeKeys.includes(x));
                    if(!keys.every(x => newobj[x] === oldObj[i][x])) {
                        updated.push(newobj);
                      }
                } else {
                    deleted.push(oldObj[i])
                }
            }    
        } 
        return {
            added,
            updated,
            deleted
        }
    },
    deepCloneCopy(obj) {
        return JSON.parse(JSON.stringify(obj));
    },
    isSame(newObj, oldObj) {
        return Object.keys(oldObj).every(x => newObj[x] === oldObj[x])
    }
} 