<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    scrollable
    max-width="500px"
    class="border-base"
    @input="inputChanged"
  >
    <v-card class="pa-3 dark-border">
      <v-row>
        <v-col cols="12" sm="4">
          <slot name="image">
            <lottie-animation
              :height="150"
              :width="200"
              :speed="0.6"
              :autoPlay="true"
              class="lottie-img-delete"
              path="../lottie/delete.json"
            />
          </slot>
        </v-col>
        <v-col cols="12" sm="8">
          <v-row>
            <v-col cols="12" class="text-h6 py-3 text-center">{{
              title
            }}</v-col>
            <v-col
              cols="12"
              class="body-2 no-word-break text-center text--secondary"
              >{{ message }}</v-col
            >
            <v-col cols="12" v-if="capture_reason">
              <BaseTextArea
                label="Reason"
                required
                v-model.trim="reason"
              />
            </v-col>
            <v-col cols="12" class="pt-0">
              <slot name="actions">
                <v-card-actions class="pa-0">
                  <v-spacer></v-spacer>
                  <BaseButton outlined @click="$emit('cancel')">Cancel</BaseButton>
                  <BaseButton :disabled="capture_reason && !reason.length" @click="$emit('confirm', reason)">OK</BaseButton>
                </v-card-actions>
              </slot>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import BaseTextArea from "./BaseTextArea.vue"
export default {
  data: () => ({
    dialog: false,
    reason: ""
  }),
  props: {
    value: {
      default: false,
    },
    message: {
      required: true,
    },
    title: {},
    capture_reason: {
      type: Boolean,
      default: false
    }
  },
  components: {
    LottieAnimation,
    BaseTextArea
  },
  watch: {
    value: {
      handler: function (val) {
        this.dialog = val;
      },
      immediate: true,
    },
  },
  methods: {
    inputChanged(val) {
      this.$emit("input", val);
    },
  },
};
</script>
<style lang="scss">
</style>