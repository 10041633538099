<template>
  <div class="splash">
    <lottie-animation
        v-if="show_lottie"
        :speed="1"
        :autoPlay="true"
        class="lottie-img-delete"
        path="../lottie/splash.json"
    />
    <transition name="bounce">
        <img v-if="!show_lottie" src="/images/logo.png" alt="Logo" class="logo">
    </transition>
  </div>
</template>

<script>
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
export default {
    data: () => ({
        show_lottie: true,
    }),
    components: {
        LottieAnimation
    },
    mounted() {
        setTimeout(() => {
            this.show_lottie = false;
        }, 2700)
    }
}
</script>

<style lang="scss" scoped>
.splash {
    background: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    position:fixed; 
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    z-index: 1000;
}
.logo {
    width: 350px;
}
.bounce-enter-active {
  animation: bounce-in 0.9s;
}
.bounce-leave-active {
  animation: bounce-in .0.9s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
</style>