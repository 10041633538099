import actions from './actions.js'
import mutations from './mutations.js'
import getters from './getters.js'

const state = {
    warranty_exclusion: [],
    registration_date: null,
    is_miles: false,

    conversion_start_date: null,

    contract_start_date: null,

    selected_customer_details: [],
    latest_mileage: 0,
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}