import actions from './actions.js'
import mutations from './mutations.js'
import getters from './getters.js'

const state = {
    annual_rnm_budget_years: [],
    annual_tyre_budget_years: [],
    payment_profiles: [],
    periods: []
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}