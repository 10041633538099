<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    :persistent="persistent"
    scrollable
    :max-width="width"
    class="border-base"
    @input="inputChanged"
  >
    <v-card class="overflow-hidden dark-border">
      <v-card-title class="primary dialog__title">
        <span class="text-h5 primary white--text">
          {{ title }}
        </span>
        <slot name="header_append"></slot>
        <v-spacer></v-spacer>
        <v-icon large color="white" @click="$emit('cancel')">mdi-close</v-icon>
      </v-card-title>
      <v-card-text
        :class="$vuetify.breakpoint.smAndDown ? 'pa-0' : ''"
        :style="{ height: height }"
        class="mt-3"
        ref="dialogBody"
      >
        <slot></slot>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="dialog__actions">
        <slot name="actions"></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
  }),
  props: {
    value: {
      default: false,
    },
    persistent: {
      default: false,
    },
    title: {},
    maxwidth: {
      default: "80vw",
    },
    mini: {
      type: Boolean
    }
  },
  watch: {
    value: {
      handler: function (val) {
        this.dialog = val;
      },
      immediate: true,
    },
  },
  methods: {
    inputChanged(val) {
      this.persistent || this.$emit("input", val);
    },
    scrollToTop() {
      this.$refs.dialogBody.scrollTop = 0;
    }
  },
  computed: {
    width: function() {
      if(this.$vuetify.breakpoint.smAndDown) {
        return '100vw';
      }
      return this.mini ? '40vw' : '80vw'
    },
    height: function() {
      if(this.$vuetify.breakpoint.smAndDown)  {
        return '80vh'
      } 
      return this.mini ? '40vh' : '80vh';
    }
  }
};
</script>

<style>
</style>