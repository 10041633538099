import common from '../../common';
import { HTTP_METHODS, HTTP_RESPONSE } from '../../enums/http';

export default class FinancialLookup {
    constructor(utils) {
        this.utils = utils;
    }
    async getFinancialRNMBudgetYears() {
        let response = await common.getRequest({
            url: `/api/getAnnualRM`,
            utils: this.utils
        });
        if(response.status === HTTP_RESPONSE.SUCCESS) {
            return response?.data?.data?.map(x => ({
                id: x.id,
                annual_rnm_budget_year: x.name
            })) || []; 
        } else {
            return [];
        }
    }
    async getFinancialTyreBudgetYears() {
        let response = await common.getRequest({
            url: `/api/getAnnualTyreBudget`,
            utils: this.utils
        });
        if(response.status === HTTP_RESPONSE.SUCCESS) {
            return response?.data?.data?.map(x => ({
                id: x.id,
                annual_tyre_budget_year: x.name
            })) || []; 
        } else {
            return [];
        }
    }
    async getFinancialPeriods() {
        let response = await common.getRequest({
            url: `/api/getPeriod`,
            utils: this.utils
        });
        if(response.status === HTTP_RESPONSE.SUCCESS) {
            return response?.data?.map(x => ({
                id: x.id,
                period: x.period
            })) || []; 
        } else {
            return [];
        }
    }
    async getFinancialPaymentProfiles() {
        let response = await common.getRequest({
            url: `/api/payment-profile`,
            utils: this.utils
        });
        if(response.status === HTTP_RESPONSE.SUCCESS) {
            return response?.data?.map(x => ({
                id: x.id,
                payment_profile: x.name
            })) || []; 
        } else {
            return [];
        }
    }
    async saveFinancialRNMBudgetYear(inputs, method = HTTP_METHODS.POST) {
        let payload = {
            name: inputs.annual_rnm_budget_year
        };
        return await common.saveRequest({
            method,
            url: method === HTTP_METHODS.POST ? `/api/addAnnualRM` : `/api/updateAnnualRM/${inputs.id}`,
            payload,
            utils: this.utils
        });
    } 
    async saveFinancialTyreBudgetYear(inputs, method = HTTP_METHODS.POST) {
        let payload = {
            name: inputs.annual_tyre_budget_year
        };
        return await common.saveRequest({
            method,
            url: method === HTTP_METHODS.POST ? `/api/addAnnualTyreBudget` : `/api/updateAnnualTyreBudget/${inputs.id}`,
            payload,
            utils: this.utils
        });
    } 
    async saveFinancialPaymentProfile(inputs, method = HTTP_METHODS.POST) {
        let payload = {
            name: inputs.payment_profile
        };
        return await common.saveRequest({
            method,
            url: method === HTTP_METHODS.POST ? `/api/payment-profile` : `/api/payment-profile/${inputs.id}`,
            payload,
            utils: this.utils
        });
    } 
    async deleteFinancialRNMBudgetYear(inputs) {
        return await common.deleteRequest({
            url: `/api/removeAnnualRM/${inputs.id}`,
            utils: this.utils
        });
    } 
    async deleteFinancialTyreBudgetYear(inputs) {
        return await common.deleteRequest({
            url: `/api/removeAnnualTyreBudget/${inputs.id}`,
            utils: this.utils
        });
    } 
    async deleteFinancialPaymentProfile(inputs) {
        return await common.deleteRequest({
            url: `/api/payment-profile/${inputs.id}`,
            utils: this.utils
        });
    } 
}