<template>
  <div class="d-inline-flex justify-start align-center height-100">
    <v-checkbox
        class="ma-0 pa-0"
        dense
        :label="label"
        :rules="rules"
        :input-value="value"
        @change="valueChanged"
        :readonly="readonly"
        :disabled="disabled"
        hide-details
        :append-icon="required ? 'mdi-star-circle': ''"
    ></v-checkbox>
  </div>
</template>

<script>
export default {
    props: {
        value: {
            default: "",
        },
        label: {
            default: ""
        },
        required: {
            type: Boolean,
            default: false
        },
        rules: {
            
        },
        disabled: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },
    methods : {
        valueChanged(val) {
            this.$emit("input", val);
        }
    }
}
</script>
<style>
.v-input--checkbox .v-icon.mdi-star-circle {
    color: red !important;
}  
</style>