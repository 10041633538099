export const STORE = {
    APP: 'app',
    AUTH: 'auth',
    VEHICLE_LOOKUP: 'vehicle_lookup',
    JOBTRACKER_LOOKUP: 'jobtracker_lookup',
    CONVERSION_LOOKUP: 'conversion_lookup',
    EQUIPMENT_LOOKUP: 'equipment_lookup',
    CONTRACT_LOOKUP: 'contract_lookup',
    SERVICE_SCHEDULE_LOOKUP: 'service_schedule_lookup',
    FINANCIAL_LOOKUP: 'financial_lookup',
    LOCATION_LOOKUP: 'location_lookup',
    VEHICLE: 'vehicle', 
    JOBTRACKER: 'jobtracker', 
  };