import { STORE } from '../../../enums/store';
import ServiceScheduleLookupService from '../../../services/lookups/serviceScheduleLookupService';
export default {
    LOAD_SERVICE_SCHEDULE_LOOKUPS({ dispatch }, utils) {
        dispatch(`${STORE.SERVICE_SCHEDULE_LOOKUP}/LOAD_JTC_CATEGORIES`, utils, { root: true });      
        dispatch(`${STORE.SERVICE_SCHEDULE_LOOKUP}/LOAD_JOB_TYPES`, utils, { root: true });      
        dispatch(`${STORE.SERVICE_SCHEDULE_LOOKUP}/LOAD_VEHICLE_SERVICE_ITEMS`, utils, { root: true });      
    },
    LOAD_JTC_CATEGORIES({ commit }, utils) {
        const serviceScheduleLookupService = new ServiceScheduleLookupService(utils);
        serviceScheduleLookupService.getJTCCategories().then(resp => {
            commit(`${STORE.SERVICE_SCHEDULE_LOOKUP}/SET_JTC_CATEGORIES`, resp, { root: true });
        }); 
    },
    LOAD_JOB_TYPES({ commit }, utils) {
        const serviceScheduleLookupService = new ServiceScheduleLookupService(utils);
        serviceScheduleLookupService.getJobTypes().then(resp => {
            commit(`${STORE.SERVICE_SCHEDULE_LOOKUP}/SET_JOB_TYPES`, resp, { root: true });
        }); 
    },
    LOAD_VEHICLE_SERVICE_ITEMS({ commit }, utils) {
        const serviceScheduleLookupService = new ServiceScheduleLookupService(utils);
        serviceScheduleLookupService.getVehicleServiceItems().then(resp => {
            commit(`${STORE.SERVICE_SCHEDULE_LOOKUP}/SET_VEHICLE_SERVICE_ITEMS`, resp, { root: true });
        }); 
    }
}