import { STORE } from '../../../enums/store';
import FinancialLookupService from '../../../services/lookups/financialLookupService';
export default {
    LOAD_FINANCIAL_LOOKUPS({ dispatch }, utils) {
        dispatch(`${STORE.FINANCIAL_LOOKUP}/LOAD_FINANCIAL_RNM_BUDGET_YEARS`, utils, { root: true });
        dispatch(`${STORE.FINANCIAL_LOOKUP}/LOAD_FINANCIAL_TYRE_BUDGET_YEARS`, utils, { root: true });
        dispatch(`${STORE.FINANCIAL_LOOKUP}/LOAD_FINANCIAL_PAYMENT_PROFILES`, utils, { root: true });         
        dispatch(`${STORE.FINANCIAL_LOOKUP}/LOAD_FINANCIAL_PERIODS`, utils, { root: true });         
    },
    LOAD_FINANCIAL_RNM_BUDGET_YEARS({ commit }, utils) {
        const financialLookupService = new FinancialLookupService(utils);
        financialLookupService.getFinancialRNMBudgetYears().then(resp => {
            commit(`${STORE.FINANCIAL_LOOKUP}/SET_FINANCIAL_RNM_BUDGET_YEARS`, resp, { root: true });
        }); 
    },
    LOAD_FINANCIAL_TYRE_BUDGET_YEARS({ commit }, utils) {
        const financialLookupService = new FinancialLookupService(utils);
        financialLookupService.getFinancialTyreBudgetYears().then(resp => {
            commit(`${STORE.FINANCIAL_LOOKUP}/SET_FINANCIAL_TYRE_BUDGET_YEARS`, resp, { root: true });
        }); 
    },
    LOAD_FINANCIAL_PAYMENT_PROFILES({ commit }, utils) {
        const financialLookupService = new FinancialLookupService(utils);
        financialLookupService.getFinancialPaymentProfiles().then(resp => {
            commit(`${STORE.FINANCIAL_LOOKUP}/SET_FINANCIAL_PAYMENT_PROFILES`, resp, { root: true });
        }); 
    },
    LOAD_FINANCIAL_PERIODS({ commit }, utils) {
        const financialLookupService = new FinancialLookupService(utils);
        financialLookupService.getFinancialPeriods().then(resp => {
            commit(`${STORE.FINANCIAL_LOOKUP}/SET_FINANCIAL_PERIODS`, resp, { root: true });
        }); 
    }
}