<template>
  <v-list-group v-if="item.items" class="custom-chevron" no-action prepend-icon="mdi-chevron-down" sub-group>
    <template v-slot:activator>
      <img class="panel-img" :src="`/icons/${item.icon}`" />
      <v-list-item-content>
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item-content>
    </template>
    <v-list-item class="pl-3" v-for="(submenu, i) in item.items" :key="i">
      <AppSidePanelItem :item="submenu" />
    </v-list-item>
  </v-list-group>
  <v-list-item v-else link :to="item.to" color="primary">
    <img class="panel-img" :src="`/icons/${item.icon}`" />
    <v-list-item-content>
      <v-list-item-title>{{ item.title }}</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: "AppSidePanelItem",
  props: ["item"],
};
</script>

<style>
.custom-chevron .v-list-item.v-list-item--active > div i.v-icon {
  transform: rotate(-180deg) !important;
}
.custom-chevron .v-list-item > div i.v-icon {
  transform: rotate(0deg) !important;
}
</style>