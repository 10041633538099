<template>
  <div>
    <label class="label" :class="{'required': required}">{{label}}</label>
    <v-select
        outlined
        dense
        :rules="rules"
        :value="internal_val"
        :items="items"
        :readonly="readonly"
        :disabled="disabled"
        @change="valueChanged"
        :item-text="item_text"
        :item-value="item_value"
    ></v-select>
  </div>
</template>

<script>
export default {
    data: () => ({
        internal_val: ""
    }),
    props: {
        value: {
            default: "",
        },
        label: {
            required: true
        },
        required: {
            type: Boolean,
            default: false
        },
        rules: {
            
        },
        items: {
            default: []
        },
        item_text: {},
        item_value: {},
        readonly: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    methods : {
        valueChanged(val) {
            this.$emit("input", val);
            this.$emit("change");
        }
    },
    watch: {
        value: {
            handler: function (val) {
                this.internal_val = val;
            },
            immediate: true
        }
    }
}
</script>

<style>

</style>