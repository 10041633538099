export default {
    SET_REGISTRATION_DATE(state, payload) {
        state.registration_date = payload;
    },
    SET_CONVERSION_START_DATE(state, payload) {
        state.conversion_start_date = payload;
    },
    SET_IS_MILES(state, payload) {
        state.is_miles = payload;
    },
    SET_CONTRACT_START_DATE(state, payload) {
        state.contract_start_date = payload;
    },
    SET_SELECTED_CUSTOMER_DETAILS(state, payload) {
        state.selected_customer_details = payload;
    },
    SET_LATEST_MILEAGE(state, payload) {
        state.latest_mileage = Number.parseInt(payload);
    },

}