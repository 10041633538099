<template>
  <v-container fluid class="pre-login-container">
    <v-row class="border-base height-100-mdup border-radius-lg">
      <v-col cols="12" md="7" class="left-section pa-0">
        <div class="bg"></div>
        <div class="content px-10">
          <h2 class="text-h3 pb-6">Welcome to CERTUS</h2>
          <p>Professional fleet management solutions</p>
        </div>
      </v-col>
      <v-col cols="12" md="5" class="right-section">
        <div>
          <img
            class="logo"
            :src="
              $vuetify.theme.dark ? '/images/logo-dark.png' : '/images/logo.png'
            "
            alt="App logo"
          />
        </div>
        <br />
        <div class="text-h5 text-center">
          {{ title }}
        </div>
        <slot></slot>
      </v-col>
    </v-row>
    <span class="app-version" v-if="appVersion">version: {{ appVersion }}</span>
  </v-container>
</template>
 <script>
import { version } from "../../package";
export default {
  name: "PreLoginLayout",
  props: {
    title: {
      default: "",
      type: String,
    },
  },
  data: () => ({
    appVersion: version,
  }),
};
</script>
<style lang="scss" scoped>
.app-version {
  position: fixed;
  opacity: $app-text-opacity;
  bottom: 10px;
  right: 10px;
}
.pre-login-container {
  height: 100vh;
  width: 100vw;
  padding: 5% 13%;
  overflow-y: auto;

  .left-section {
    border-top-left-radius: $app-border-radius-lg;
    border-bottom-left-radius: $app-border-radius-lg;
    color: $white;
    background-image: url(/images/login-background.jpg);
    background-size: cover;
    position: relative;
    div.bg {
      height: 100%;
      width: 100%;
      background: $dark-black;
      opacity: 0.6;
      border-top-left-radius: $app-border-radius-lg;
      border-bottom-left-radius: $app-border-radius-lg;
    }
    div.content {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: flex-start;
    }
    p {
      font-size: 20px;
    }
  }

  .right-section {
    padding: 5% 5%;
  }

  .logo {
    height: 35px;
    margin-left: 50%;
    transform: translateX(-50%);
  }
}
@media screen and (max-width: 960px) {
  .left-section {
    display: none !important;
  }
  .pre-login-container {
    .row {
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
}

@media screen and (orientation: portrait) {
  .pre-login-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>