import common from '../common';
import arrayHelper from '../helpers/arrayHelper';
import { HTTP_METHODS, HTTP_RESPONSE } from '../enums/http';
// import { STORE } from '../enums/store'
export default class RoleAndPermission {
    constructor(utils) {
        this.utils = utils;
    }

    async getAllPermissions() {
        let response = await common.getRequest({
            url: `/api/getAllPermission`,
            utils: this.utils
        });
        if(response.status === HTTP_RESPONSE.SUCCESS) {
            return response.data.map(x => ({
                id: x.id,
                name: x.name
            })); 
        } else {
            return [];
        }
    }
    async getAllRoles() {
        let response = await common.getRequest({
            url: `/api/getAllRoles`,
            utils: this.utils
        });
        if(response.status === HTTP_RESPONSE.SUCCESS) {
            return response.data.map(x => ({
                id: x.id,
                name: x.name
            })); 
        } else {
            return [];
        }
    }
    async getUserPermission(id) {
        let response = await common.getRequest({
            url: `/api/getAllPermissionByUserId/${id}`,
            utils: this.utils
        });
        if(response.status === HTTP_RESPONSE.SUCCESS) {
            return response.data?.map(x => x.name) || []; 
        } else {
            return [];
        }
    }
    async getRolePermission(id) {
        let response = await common.getRequest({
            url: `/api/getAllPermissionByRoleId/${id}`,
            utils: this.utils
        });
        if(response.status === HTTP_RESPONSE.SUCCESS) {
            return response.data?.map(x => x.name) || []; 
        } else {
            return [];
        }
    }
    async updateUserPermissions({ user_id, old_permissions, new_permissions, all_permissions }) {
        let { added, deleted } = arrayHelper.difference(new_permissions, old_permissions);
        added.length && await this.addUserPermission({
            user_id,
            permissions: added,
            all_permissions
        });
        deleted.length && await this.removeUserPermission({
            user_id,
            permissions: deleted,
            all_permissions
        })
        return;
    }
    async updateRolePermissions({ role_id, old_permissions, new_permissions, all_permissions }) {
        let { added, deleted } = arrayHelper.difference(new_permissions, old_permissions);
        added.length && await this.addRolePermission({
            role_id,
            permissions: added,
            all_permissions
        });
        deleted.length && await this.removeRolePermission({
            role_id,
            permissions: deleted,
            all_permissions
        })
        return;
    }
    async addUserPermission({user_id, permissions, all_permissions}) {
        let payload = {
            user_id,
            permissions: permissions.map(x => all_permissions.find(y => y.name === x)?.id)
        };
        await common.saveRequest({
            method: HTTP_METHODS.POST,
            url: `/api/addUserPermission`,
            payload,
            utils: this.utils
        });
        return;
    }
    async removeUserPermission({user_id, permissions, all_permissions}) {
        for (let i = 0; i < permissions.length; i++) {
            await common.deleteRequest({
                url: `/api/removeUserPermission/${user_id}/${all_permissions.find(x => x.name === permissions[i])?.id}`,
                utils: this.utils
            });
        }
        return;
    }
    async addRolePermission({role_id, permissions, all_permissions}) {
        let payload = {
            role_id,
            permissions: permissions.map(x => all_permissions.find(y => y.name === x)?.id)
        };
        await common.saveRequest({
            method: HTTP_METHODS.POST,
            url: `/api/assignRolePermission`,
            payload,
            utils: this.utils
        });
    }
    async removeRolePermission({role_id, permissions, all_permissions}) {
        for (let i = 0; i < permissions.length; i++) {
            await common.deleteRequest({
                url: `/api/removeRolePermission/${role_id}/${all_permissions.find(x => x.name === permissions[i])?.id}`,
                utils: this.utils
            });
        }
        return;
    }
    async assignUserRole({role_id, user_id }) {
        let payload = {
            role_id,
            user_id
        };
        return await common.saveRequest({
            method: HTTP_METHODS.POST,
            url: `/api/assignUserRole`,
            payload,
            utils: this.utils
        });
    }
    async removeUserRole({role_id, user_id }) {
        return await common.deleteRequest({
            url: `/api/removeUserRole/${user_id}/${role_id}`,
            utils: this.utils
        })
    }
    async createRole({name }) {
        let payload = {
            name
        };
        return await common.saveRequest({
            method: HTTP_METHODS.POST,
            url: `/api/createRole`,
            payload,
            utils: this.utils
        });
    }
    async deleteRole(role) {
        return await common.deleteRequest({
            url: `/api/deleteRole/${role.id}`,
            utils: this.utils
        });
    }
    async deleteUser(user) {
        return await common.deleteRequest({
            url: `/api/deleteUser/${user.id}`,
            utils: this.utils
        });
    }
}