<template>
  <v-avatar
      :color="iconcolor"
      :size="size"
    >
      <span class="text-h6" style="color: #5a5757;">{{avataricon}}</span>
    </v-avatar>
</template>

<script>
export default {
    data() {
        return {
            color: ["lime lighten-2", "cyan lighten-3", "orange", "green accent-2", "yellow lighten-1", "amber lighten-3", "deep-orange lighten-3", "purple lighten-4", "blue lighten-4", "light-green accent-1"]
        }
    },
    props: {
        size: {
            default: 40
        },
        name: {
            required: true
        }
    },
    computed: {
        avataricon: function () { return this.name[0].toUpperCase(); },
        iconcolor: function() {
            return this.color[(this.name?.length || 0) % this.color.length];
        }
    },
}
</script>

<style>

</style>