import common from '../../common';
import { STORE } from '../../enums/store';
import { HTTP_METHODS, HTTP_RESPONSE } from '../../enums/http';

export default class ServiceScheduleLookup {
    constructor(utils) {
        this.utils = utils;
    }
    async getJTCCategories() {
        let response = await common.getRequest({
            url: `/api/getjobCategeory`,
            utils: this.utils
        });
        if(response.status === HTTP_RESPONSE.SUCCESS) {
            return response?.data?.data?.map(x => ({
                id: x.id,
                category: x.job_name
            })) || []; 
        } else {
            return [];
        }
    }
    async getVehicleServiceItems() {
        let response = await common.getRequest({
            url: `/api/getVehicleServiceItem`,
            utils: this.utils
        });
        if(response.status === HTTP_RESPONSE.SUCCESS) {
            return response?.data?.map(x => ({
                id: x.id,
                vehicle_service_item: x.name
            })) || []; 
        } else {
            return [];
        }
    }
    async getJobTypes() {
        const vehicle_service_items = this.utils.$store.state[STORE.SERVICE_SCHEDULE_LOOKUP].vehicle_service_items;
        let response = await common.getRequest({
            url: `/api/getJobtype`,
            utils: this.utils
        });
        if(response.status === HTTP_RESPONSE.SUCCESS) {
            return response?.data?.data?.map(x => ({
                id: x.id,
                lookup_job_category_id: x.lookup_job_category_id,
                job_type_code: x.jtc_code,
                job_type: x.job_type,
                is_routine: x.is_routine == 1 ? true : false,
                vehicle_service_items: x.v_service_item?.length ? vehicle_service_items.filter(y => x.v_service_item.indexOf(y.id) != -1) : []
            })) || []; 
        } else {
            return [];
        }
    }
    async saveJTCCategory(inputs, method = HTTP_METHODS.POST) {
        let payload = {
            job_name: inputs.category
        };
        return await common.saveRequest({
            method,
            url: method === HTTP_METHODS.POST ? `/api/saveJobCategeory` : `/api/updatejobCategeory/${inputs.id}`,
            payload,
            utils: this.utils
        });
    }
    async saveJobType(inputs, method = HTTP_METHODS.POST) {
        let payload = {
            lookup_job_category_id: inputs.lookup_job_category_id,
            jtc_code: inputs.job_type_code,
            job_type: inputs.job_type,
            is_routine: inputs.is_routine,
            v_service_item: inputs.vehicle_service_items?.map(x => x.id) || null,
        };
        return await common.saveRequest({
            method,
            url: method === HTTP_METHODS.POST ? `/api/jobtype` : `/api/updateJobType/${inputs.id}`,
            payload,
            utils: this.utils
        });
    }
    async saveVehicleServiceItem(inputs, method = HTTP_METHODS.POST) {
        let payload = {
            name: inputs.vehicle_service_item
        };
        return await common.saveRequest({
            method,
            url: method === HTTP_METHODS.POST ? `/api/vehicleServiceItem` : `/api/updateVehicleServiceItem/${inputs.id}`,
            payload,
            utils: this.utils
        });
    }
    async deleteJTCCategory(inputs) {
        return await common.deleteRequest({
            url: `/api/removejobCategeory/${inputs.id}`,
            utils: this.utils
        });
    } 
    async deleteJobType(inputs) {
        return await common.deleteRequest({
            url: `/api/removeJobType/${inputs.id}`,
            utils: this.utils
        });
    } 
    async deleteVehicleServiceItem(inputs) {
        return await common.deleteRequest({
            url: `/api/removeVehicleServiceItem/${inputs.id}`,
            utils: this.utils
        });
    } 
}