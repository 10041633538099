<template>
  <div class="d-flex justify-space-between align-center py-1">
    <div>{{label}}</div>
    <v-switch
      class="ma-0"
      v-model="themetoggler"
      color="primary"
      inset
      :ripple="false"
      hide-details>
    </v-switch>
  </div>
</template>

<script>
export default {
  props: ["label"],
  computed: {
    themetoggler: {
      get() {
        return this.$vuetify.theme.dark;
      },
      set(val) {
        this.$vuetify.theme.dark = val;
        localStorage.setItem("theme", val);
      },
    },
  },
};
</script>