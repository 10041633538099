export const HTTPSTATUS = {
    SUCCESS: 200,
    CREATED: 201,
    NO_CONTENT: 204,
    BAD_REQUEST: 400,
    TOO_MANY_REQUEST: 429,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    INTERNAL_SERVER_ERROR: 500
}

export const HTTP_RESPONSE = {
    SUCCESS: "success",
    FAILED: "failed",
}

export const HTTP_METHODS = {
    GET: "get",
    POST: "post",
    PUT: "put",
    DELETE: "delete"
}