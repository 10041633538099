import axios from "axios";
export const HTTP = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: {
    'X-Requested-With': 'XMLHttpRequest'
  },
  withCredentials: true
});

export const HTTP_WITH_FILE = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'multipart/form-data'
  },
  withCredentials: true
});