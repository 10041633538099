import common from '../../common';
import { HTTP_METHODS, HTTP_RESPONSE } from '../../enums/http';

export default class LocationLookupService {
    constructor(utils) {
        this.utils = utils;
    }
    async getLocations() {
        let response = await common.getRequest({
            url: `/api/getLocation`,
            utils: this.utils
        });
        if(response.status === HTTP_RESPONSE.SUCCESS) {
            return response?.data?.data?.map(x => ({
                ...x,
                location_disp   : x.location_name + " " + (x.address1 || "") + " " + ( x.address2 || "" ),
                address_disp    : ( x.address1 || "" ) + " " +
                                  ( x.address2 || "" ) + " " +
                                  ( x.address3 || "" ) + " " +
                                  ( x.address4 || "" ) + " " +
                                  ( x.address5 || "" ) + " " +
                                  ( x.postcode || "" )
            })) || []; 
        } else {
            return [];
        }
    }
    async saveLocation(inputs, method = HTTP_METHODS.POST) {
        let payload = {
            location_name: inputs.location_name,
            address1: inputs.address1,
            address2: inputs.address2,
            address3: inputs.address3,
            address4: inputs.address4,
            address5: inputs.address5,
            postcode: inputs.postcode,
        };
        return await common.saveRequest({
            method,
            url: method === HTTP_METHODS.POST ? `/api/saveLocation` : `/api/updateLocation/${inputs.id}`,
            payload,
            utils: this.utils
        });
    }
    async deleteLocation(inputs) {
        return await common.deleteRequest({
            url: `/api/removeLocation/${inputs.id}`,
            utils: this.utils
        });
    } 
}