import { STORE } from '../../../enums/store';
import ConversionLookupService from '../../../services/lookups/conversionLookupService';
export default {
    LOAD_CONVERSION_LOOKUPS({ dispatch }, utils) {
        dispatch(`${STORE.CONVERSION_LOOKUP}/LOAD_VEHICLE_CONVERTERS`, utils, { root: true });      
        dispatch(`${STORE.CONVERSION_LOOKUP}/LOAD_VEHICLE_WARRANTY_EXCLUDE_ITEMS`, utils, { root: true });      
    },
    LOAD_VEHICLE_CONVERTERS({ commit }, utils) {
        const conversionLookupService = new ConversionLookupService(utils);
        conversionLookupService.getVehicleConverters().then(resp => {
            commit(`${STORE.CONVERSION_LOOKUP}/SET_VEHICLE_CONVERTERS`, resp, { root: true });
        }); 
    },
    LOAD_VEHICLE_WARRANTY_EXCLUDE_ITEMS({ commit }, utils) {
        const conversionLookupService = new ConversionLookupService(utils);
        conversionLookupService.getVehicleWarrantyExcludedItems().then(resp => {
            commit(`${STORE.CONVERSION_LOOKUP}/SET_VEHICLE_WARRANTY_EXCLUDE_ITEMS`, resp, { root: true });
        }); 
    }
}