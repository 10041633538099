<template>
  <v-tooltip bottom :open-delay="300" :color="color">
      <template v-slot:activator="{ on, attrs }">
        <span class="mr-1">
            <v-icon 
              dense
              @click="$emit('click')" 
              v-bind="attrs" 
              v-on="on" 
              :color="color">
              {{icon}}
            </v-icon>
        </span>
      </template>
      <span>{{text}}</span>
    </v-tooltip>
</template>
<script>
export default {
    props: {
        color: {
            default: 'primary'
        },
        icon: {
            required: true
        },
        text: {
            required: true
        }

    }
}
</script>