<template>
  <div class="sidepanel">
    <v-list dense rounded>
      <br />
      <v-subheader>
        <img class="logo cursor-pointer" :src="$vuetify.theme.dark ? '/images/logo-dark.png' : '/images/logo.png'" alt="App logo" @click="$router.push('/');" />
      </v-subheader>
      <br />
      <BaseProgressBar v-if="!show_menu" />
      <div v-else v-for="item in filtered_menu" :key="item.title">
        <AppSidePanelItem :item="item" />
      </div>
    </v-list>
    <div class="footer pl-5">
      <span class="overline">Powered by </span
      ><a class="pt-1" href="http://cc-s.uk" target="_blank"
        ><img src="../../assets/ccs-logo.png" alt="CCS"
      /></a>
    </div>
  </div>
</template>

<script>
import { STORE } from '../../enums/store';
import mainMenu from '../../mainMenu'
import AppSidePanelItem from './AppSidePanelItem.vue'
export default {
  data: () => ({
    filtered_menu: [],
    show_menu: true,
    menu_items: mainMenu,
  }),
  components: {
    AppSidePanelItem
  },
  watch: {
    "$store.state.auth.permissions": {
      handler: function() {
        this.filterMenu()
      },
      immediate: true
    },
    "$store.state.auth.permission_loading": {
      handler: function(val) {
        this.show_menu = !val;
      },
      immediate: true
    }
  },
  methods: {
    hasAnyPermission(permissionList) {
      return Object.values(permissionList).some(permission => this.$store.state[STORE.AUTH].permissions.includes(permission));
    },
    getFiltereditem(item) {
      let tempitem = null;
      if(item.items) {
        // !item.role is url accessible for all logged in users
        if(!item.permissions || this.hasAnyPermission(item.permissions)) {
          tempitem = {
            title: item.title,
            icon: item.icon,
            items: item.items.map(x => this.getFiltereditem(x)).filter(x => x)
          }
        }
      } else {
        if(!item.permissions || this.hasAnyPermission(item.permissions)) {
          tempitem = { ...item };
        }
      }
      return tempitem;
    },
    filterMenu() {
      let filteredmenu = [];
      for(let i = 0; i < this.menu_items.length; i++) {
        filteredmenu.push(this.getFiltereditem(this.menu_items[i]));
      }
      this.filtered_menu = filteredmenu.filter(x => x).filter(x => {
        if(x.items) { 
          return x.items.length;  
        } else {
          return true;
        }
      });
    }
  }
};
</script>

<style lang="scss">
.sidepanel {
  height: calc(100% - 50px);
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;

  .v-list-group--sub-group {
    width: 100%;
    .v-list-group__items .v-list-item {
      padding-left: 24px !important;
    }
  }
  .v-list-group__header {
    padding-left: 16px !important;
  }
  .v-list-group__header__prepend-icon {
    margin-right: 5px !important;
    position: absolute;
    right: 10px;
  }
  .logo {
    height: 27px;
    margin-left: 50%;
    transform: translateX(-50%);
  }
  .panel-img {
    height: 34px;
    width: 34px;
    margin-right: 15px;
  }
  .footer {
    height: 50px;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transform: translateX(-50%);
    span {
      opacity: $app-text-opacity;
      word-break: keep-all;
    }
    img {
      height: 50px;
      cursor: pointer;
    }
  }
}
</style>