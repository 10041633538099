export const JOB_TRACKER_STATUS = {
    OPEN_E: 'Estimated',
    OPEN_A: 'Actual',
    QUERY: 'Query',
    FINAL: 'Final',
    CLOSED: 'Closed',
}
export const JOB_TRACKER_STATUS_CODE = {
    OPEN_E: 1,
    OPEN_A: 2,
    QUERY: 3,
    FINAL: 4,
    CLOSED: 5,
}

export const ALERT_TYPE = {
    SUCCESS: 'success',
    ERROR: 'error',
    INFO: 'info',
    WARNING: 'warning'
}