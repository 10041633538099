export default {
    SHOW_NOTIFICATION(state, { message, type }) {
        state.notification = { message , type };
    },
    SET_OVERLAY_LOADING(state, { status, message }) {
        state.overlay_loading = { status , message };
    },
    SET_ALL_SUPPLIERS(state, payload) {
        state.suppliers = payload;
    },
    SET_ALL_CUSTOMERS(state, payload) {
        state.customers = payload;
    },
    SET_TITLE(state, payload) {
        document.title = `CERTUS: ${ payload }`;
        state.title = payload;
    },
}