<template>
  <v-progress-linear
    color="primary"
    indeterminate
    height="6"
    ></v-progress-linear>
</template>
<script>
export default {
}
</script>