export default {
    UPDATE_USER(state, user) {
        state.user = user;
    },
    SET_CURRENT_USER_PERMISSIONS(state, payload) {
        state.permissions = payload;
    },
    SET_PERMISSION_LOADING(state, payload) {
        state.permission_loading = payload;
    },
}